export default [
    {
        path: '/downloads',
        name: 'downloadcenter.index',
        component: () => import('@/views/Downloadcenter/Downloads.vue'),
        meta: {
            canAddNav: true,
            pageTitle: 'Downloadcenter',
            resource: 'downloadcenter',
            breadcrumb: [
                {
                    text: 'Downloads',
                    active: true,
                },
            ],
        },
    },

]
